import React, { Component } from "react";
import pronounciation from "../assets/sounds/michalnierebinski.mp3";
import logo from "../assets/images/logo.svg";

class About extends Component {
  render = () => {
    return (
      <div className="oneColumn">
        <h1>Who am I?</h1>
        <p>
          My name is Michał Nierebiński{" "}
          <em>
            [<strong>Mee</strong>-how Knee-eh-reh-<strong>bins</strong>-kee]
          </em>{" "}
          (although this 'ń' character should be pronounced exactly like Spanish
          'ñ' in <em>mañana</em> or <em>España</em>).
        </p>
        <div className="pronounce">
          <h5 className="proFlex"> That's nearly a correct pronounciation: </h5>
          <audio controls className="proFlex">
            <source src={pronounciation} type="audio/mpeg" />
          </audio>
        </div>
        <p className="or">
          <em>
            Please, don't be discouraged by any social media content embedded
            here
          </em>{" "}
          (≥o≤)
        </p>
        <p>
          Living in Warsaw,{" "}
          <span role="img" aria-label="Polish flag">
            🇵🇱
          </span>
          . Thirty something. Married. Thinks pouring ice-cold water on fresh
          yerba mate leaves is a great idea for summer. Prefers dogs over cats,
          but doesn't own any.
        </p>
        <p>
          I am glad that the days of dial-up internet are long gone. So were my
          parents back then. I am not a digital native, yet from my earliest
          days I remember I wanted to get as digital as it was then possible.
        </p>
        <p>
          Yup, I also liked to play football/soccer and ride my bike as a kid.
          Those were good days.
        </p>
        <p>And this days are pretty good too.</p>
        <h2>Why "Can into space?"</h2>
        <div className="caisLogo">
          <img src={logo} alt="can into space" />
        </div>
        <p>
          It all came from{" "}
          <a
            href="https://en.wikipedia.org/wiki/Polandball"
            title="WebForms now"
            target="_blank"
            rel="noopener noreferrer"
          >
            Polandball (or countryballs)
          </a>{" "}
          comics. No, I have never had anything to do with their creation, and
          you can't really call me a big fan of these.
        </p>
        <p>
          Of course, I found some stereotypes on Poland and Polish people pretty
          humorous, even if not necessarily real, like with Poland complaining
          about not being able to fly into space. Thus the "Poland cannot into
          space" <em>(Polandball can't use proper English, you know)</em>{" "}
          punchline was born.{" "}
        </p>
        <p>
          We're not a space power like USA, former USSR or China nowadays, and I
          don't think Polish ambitions lie there. But there was{" "}
          <a
            rel="noopener noreferrer"
            href="https://en.wikipedia.org/wiki/Miros%C5%82aw_Hermaszewski"
            title="Polish guy in space"
            target="_blank"
          >
            one Polish guy who "could into space"
          </a>
          . Not that it matters to me. But I can change the punchline to a more
          positive one.
        </p>
        <p>
          As for Polandball memes, they can be somewhat controversial and
          politically incorrect. I'm not endorsing them. I just want to state
          how memetic culture shapes my mind.
        </p>
        <h2>The programmer</h2>
        <p>
          Having over 12 years of professional experience, I've developed a
          certain sense of awe how much there's for me to master. And I love it.
          I love professional challenges.
        </p>
        <p>
          I love learning new ways to create software: languages, frameworks and
          architectural concepts. When I was younger I always wanted to learn
          all the newest stuff. Thank God I'm older and more patient now.{" "}
        </p>
        <p>
          Take a look at{" "}
          <a href="/projects" title="Projects portfolio">
            my projects
          </a>
          . As I look at them now or maybe at me working on them back then, I'd
          propably do thing differently. And I'm glad of that. That means I
          understood the job and gained experience. If you're a programmer, you
          probably like this feeling too.
        </p>
        <p>
          I started out as a C#/ASP.NET programmer in the days when ASP.NET 2.0
          and ancient{" "}
          <a
            href="https://www.asp.net/web-forms"
            title="WebForms now"
            target="_blank"
            rel="noopener noreferrer"
          >
            WebForms were king
          </a>
          . Others may have preferred PHP back then, yet I was allured to static
          typing. My love for this concept still flourishes. I'm not a fanatic
          though.
        </p>
        <p>
          Then one job came out as a surprise. The recruitment ad said they were
          searching for a C#/ASP.NET developer. During the interview I was
          informed that some of the website's code is not in C# but in Visual
          Basic. Yikes! But on the job it appeared that <strong>most</strong> of
          the code is actually pure classic ASP <em>(not ASP.NET)</em> in
          VBScript. Lukcy me - a new challenge. This time not with a new
          language, but with an old one, though.
        </p>
        <p>
          After some time I got back to C#, then I was offered to jump on a JAVA
          ship. It sounded refreshing, so I jumped there. It wasn't that hard,
          besides learning all the needed frameworks. Fortunately the team and
          framework communities were more than helpful.
        </p>
        <p>
          Then a time came to change technologies once more and I joined node.js
          developers family, and after few time I was asked to do what I had
          been proposing for quite a time: organizing a native mobile
          development teams (iOS and Android). And I've been enjoying creating
          applications for iPhone ever since.
        </p>
        <h2>The leader</h2>
        <p>
          I was really flattered when my manager asked me to fill the role of
          team leader. I always liked working with people, but leading them was
          a completely new role. I'm still grateful for her trust in me. Lucky
          for me, the guys were fantastic and it was a pleasure to work with
          them.
        </p>
        <p>
          And to be honest, I think that every team I led since that moment was
          great. It's easy to lead, when the communitation among programmers is
          good, we trust each other to fullfil our tasks and we can identify
          ourselves with the product.
        </p>
        <div className="embedContainer">
          <div className="tumblr-post" data-href="https://embed.tumblr.com/embed/post/uFad_MPC9fdm9OiCy-rmag/164140479516" data-did="101a7a93da19e1e060a6609176e75a7d5af71a60"><a href="https://shots.caninto.space/post/164140479516/yeah-well-im-the-lord-of-time-and-im-here-to">https://shots.caninto.space/post/164140479516/yeah-well-im-the-lord-of-time-and-im-here-to</a></div>
          <p>
            I often hear managers from various companies boasting, that in their
            workplace employees are the most important assets. It's populat to
            say that nowadays. But I really try to live by that words. Sometimes
            it means that you have to stand up and say: "Well, your product may
            be great, but it means nothing if you can't treat the development
            team fair".
          </p>
          <p>
            And that's kind of what my role is. Not a boss, but a colleague with
            slighly different responsibilites. Do I have to be a good
            programmer? Sure! And what about guys that are even better
            developers than me? I love when they join the team. There's so much
            I can learn from them. And on the other side: my experience in our
            domain, our company's way of doing things and rich knowledge of all
            the technologies we use in all the teams for multitue of products we
            release and maintain, can help the new guy with his (or her)
            immersion into the new world. The bigger the organization, the more
            important team leader role is.
          </p>
          <p>
            Don't even think of being a leader if you:
            <br />
            Don't trust your organizations values or way of doing things,
            <br />
            Cannot identify yourself with the product,
            <br />
            or you just can't stand the idea of taking one for the team.
          </p>
          <p>You won't inspire the team if you are uninspired or burnt out.</p>
          <p>
            Trust the organization, love the product, fight for the team - and
            you'll succeed. That has worked for me for over seven years now.
          </p>
          <h2>The geek</h2>
          <p>
            Do you know what an Amstrad-Schneider was? It was the first computer
            I ever played on. Hard stuff, weird floppy disks. My grandfather
            used to borrow it from his work, so I could play a bit. Well, if I
            recall correctly, even my parents played the everlasting hits like
            Manic Miner, Fruitty Frank and such.
          </p>
          <p>
            Then I went through all of this. C64, various PC clones, some
            handhelds, an XBox 360 and now a PS4. Still, I am just a casual
            gamer. I've never had the time or patience to succumb completely in
            video games world.
          </p>
          <p>
            A few years ago someone helped me discover "Doctor Who". And I loved
            it so much, that my wife can't even understand that. Yes, I watch a
            lot of shows, but this one is something completely different for me.{" "}
          </p>
          <div className="embedContainer">
            <div
              className="tumblr-post"
              data-href="https://embed.tumblr.com/embed/post/uFad_MPC9fdm9OiCy-rmag/164028391086"
              data-did="2bd8856ed120668520c0975ab4b8208cfc0e48ae"
            >
              <a href="http://shots.caninto.space/post/164028391086/hayleymshall-3x0210x03-still-martha-bill">
                http://shots.caninto.space/post/164028391086/hayleymshall-3x0210x03-still-martha-bill
              </a>
            </div>
          </div>
          <p>
            In the past I've done a lot of classic RPG gaming with my high
            school friends. And do you remember any social media before the age
            of Facebook? There were such, mostly run by various fandoms. I spent
            a lot of time playing single player TIE Fighter with other Imperial
            maniacs on{" "}
            <a
              href="https://tc.emperorshammer.org/"
              title="Emperor's Hammer TIE Corps"
              target="_blank"
              rel="noopener noreferrer"
            >
              TIE Corps
            </a>
            . And then I was stupid enough to compromise my career as an
            Imperial pilot. Still, those were the days, when you could do just
            anything, if you were creative enough, and not be afraid of getting
            eaten by the Internet giants.
          </p>
          <p>
            There was a lot more of my geeky interests: (card games, miniature
            games), but I could go on like this for hours. Still, you can
            contact me and ask about this too.
          </p>
        </div>
      </div>
    );
  };
}

export default About;
