import React, { Component } from "react";

const image = (url, alt) => {
  if (url) {
    return <img src={url} alt={alt} />;
  }

  return undefined;
};

const setInnerHTML = (content) => {
  return { __html: content };
};

class Podcast extends Component {
  render = () => {
    return (
      <div className="cast">
        <h3>
          <a
            href={this.props.url}
            title={this.props.title}
            target="_blank"
            rel="noreferrer"
          >
            {this.props.title}
          </a>
        </h3>
        {image(this.props.imageUrl, this.props.title)}

        <p
          className="descCast inCast"
          dangerouslySetInnerHTML={setInnerHTML(this.props.description)}
        />
        <div className="footCast inCast">
          <p>
            <strong>
              Go to{" "}
              <a
                href={this.props.url}
                title={this.props.title + "'s site"}
                target="_blank"
                rel="noreferrer"
              >
                the podcast's website
              </a>
            </strong>
          </p>
          <h5>Language: {this.props.language || "🇬🇧"}</h5>
        </div>
      </div>
    );
  };
}

export default Podcast;
