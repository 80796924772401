import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Route, Routes, Navigate } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";
import HomePageContainer from "./HomePageContainer";
import Page404 from "./Page404";
import About from "./About";
import PodcastContainer from "./PodcastContainer";
import ProjectSite from "./ProjectSite";
import "../css/application.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIOS: props.isIOS,
    };
  }

  componentDidMount() {
    if (this.state.isIOS) {
      return;
    }

    this.toggleNavBar = this.navBar.handleToggling;
    window.addEventListener("scroll", this.handleNavBarScroll);
  }

  componentWillUnmount() {
    if (this.stateisIOS) {
      return;
    }
    window.removeEventListener("scroll", this.handleNavBarScroll);
  }

  handleNavBarScroll = () => {
    let navBarBottom = ReactDOM.findDOMNode(this.navBar).getBoundingClientRect()
      .bottom;
    let startElement = ReactDOM.findDOMNode(this.refs.ausweissBitte);
    let startOffset = this.getOffset(startElement);
    let windowScrollTop = window.pageYOffset;

    windowScrollTop >= startOffset.top - navBarBottom
      ? this.toggleNavBar(true)
      : this.toggleNavBar(false);
  };

  getOffset = (element) => {
    let bounding = element.getBoundingClientRect();
    return {
      top: bounding.top + document.body.scrollTop,
      left: bounding.left + document.body.scrollLeft,
    };
  };

  render() {
    return (
      <main role="main">
        <NavBar
          ref={(navBar) => {
            this.navBar = navBar;
          }}
          isWhitened={this.state.isIOS}
        />
        <div className="withoutBar">
          <div className="appHeader" />
          <div ref="ausweissBitte" />
          <div className="container mainContainer contentWrapper">
            <Routes>
              <Route exact path="/" element={<HomePageContainer />} />
              <Route exact path="/about-me" element={<About />} />
              <Route exact path="/404.html" element={<Page404 />} />
              <Route exact path="/podcasts" element={<PodcastContainer />} />
              <Route exact path="/projects" element={<ProjectSite />} />
              <Route path="*" element={<Navigate to="/404.html" />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </main>
    );
  }
}

export default App;
