export const HomeTileData = [
  {
    header: "Hi.",
    subheader: "My name is Michał Nierebiński",
    content: [
      'Born and based in 🇵🇱. I\'m a <a href="/about-me" title="Me, myself and I">senior software engineer</a> who\'s not afraid of handling full-stack. I lead an iOS development team.',
      "If you ask if I'm addicted to mobile and the internet, I can only tell you this: I remember the vintage dub-step music played by dial-up modems very well and I know what parents complaining about high telephone bills sound like.",
      'I\'m also a complete geek. But not an <em>otaku</em>. Yet. So <a href="/about-me" title="Me, myself and I">who am I</a>?',
      '<h4><span class="or">(≥o≤)</span></h4>',
      'You can reach me via <a href="mailto:contact@caninto.space" title="Send me an email">email</a>.',
    ],
    image: {
      file: "images/chess-king.png",
      alt: "The king stays the king, a'ight?",
    },
  },
  {
    header: "What I do",
    subheader: "I create media future. And banking too",
    content: [
      "In my 16 years of professional experience, I've always worked with the biggest Polish media companies. A couple of years ago I started leading an iOS team for a mobile banking application",
      "For 7 years I've been engaged in iOS projects. Previously I have developed CMSes and portals using those CMSes as their source of content for several years. Nearly all of my projects were built for big mass-media companies.",
      'Feel free to look at my <a href="/projects" title="Projects portfolio">projects portfolio</a>, where you can find the most noteworthy of my projects. Of course, most of them were created by a team that I led or was a member of. Some of them were my sole responsibility, though.',
    ],
    image: {
      file: "images/notepad.png",
      alt: "Let's start coding!",
    },
  },
  {
    header: "Follow me on Mastodon",
    subheader: "(no, not on Twitter)",
    content: [
      "My phone's battery stats told me, Twitter was responsible for over 33% of its usage. And I used a lot of apps.",
      'Most of great interactions or finding interesting things to read happens there, though. Not on other social networks. Thanks to the Boring Company Guy, his failures and his hateful behavior, I\'ve decided to jump ships nad try Mastodon. Don\'t hesitate to <a target=\"_blank\" rel=\"me\" href=\"https://mastodon.social/@CanIntoSpace\" title=\"Follow @CanIntoSpace\">follow me there</a> and say hello!',
      "Don't worry, I'm not spamming any commercial offers or endorse anything there. At least not in commercial way. This is my new account, mostly for professional stuff. But do expect some popcultural nerdiness",
    ],
    image: {
      file: "images/mastodon.png",
      alt: "You've got a new follower",
            className: "wx660x tileImage"
    },
  },
  {
    header: "Addicted to podcasts",
    subheader: "I discovered them pretty late",
    content: [
      "Podcasts are my new found love. They compete quite successfully with my Apple Music subscription.",
      "I have made Tuesday my podcast listening day. Every week I realize that I haven't listened to all of them, so Wednesday is a podcast day also.",
      'Check out what <a href="/podcasts" title="My favourite podcasts"> podcasts I listen to</a> on a weekly basis. Some of them are important for me because of my professional interests, some of them give me excelent insights in modern culture and civilization. You\'ll find some historical ones there and <em>(of course)</em> geeky.',
    ],
    image: {
      file: "images/vintage-mic.png",
      alt: "Now, listen here...",
    },
  },
  {
    header: "Flickering memories",
    specialClassName: "instaBox",
    subheader: "I just love Instagram",
    content: [
      'I always fill my <a href="https://www.instagram.com/loyolny/" title="loyolny @instagram" target="_blank" rel="noreferrer">Instagram account</a> during the summer vacation most intensively. There are always so many astonishing things in the nearby nature, old ruined building or local monuments. And geeky stuff. I always shoot some pics of me doing geeky stuff.',
      "The same goes for my followers: I enjoy seeing their pics of architecture, geekiness, hafing fun with their kids and friends. I really like spending time swiping and double tapping the Instagram feed.",
      "Seen my latest selfie?",
    ],
    image: {
      file: "images/follow-insta.png",
      alt: "See you on instagram",
      linkURL: "https://www.instagram.com/loyolny/",
      linkTitle: "Let's meet on Instagram",
      openInNewWindow: true,
    },
  },
];

// const HiddenData = [
//   {
//     header: "Geek's  guilty pleasures",
//     subheader: "That's why I'm back on Tumblr",
//     content: [
//       "I gave up on sites like <em>soup.io</em> or <em>Tumblr</em> a long time ago. Why have I decided to come back to Tumblr? Geeky stuff!",
//       "It's just there's an enormous load of animated gifs, memes or fan art based on my favourite TV shows <em>(Hello, I'm the Doctor)</em> or computer games. I repost some of them on <a href=\"http://shots.caninto.space\" target=\"_blank\">Shots @ caninto.space</a>, where I've started to build my geeky scrap folder.",
//       'There\'s even an <a href="http://shots.caninto.space/ask" title="Don\'t ask me anything" target="blank">AMA section</a>, so feel free not to use it.'
//     ],
//     image: {
//       file: "images/doReblog.png",
//       alt: "Edit by me",
//       className: "tileImage wideImage"
//     }
//   }
// ];

export const ProjectData = [
  {
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fparibas.png?alt=media&token=08b851c4-16ee-4659-90d2-dce32854c9fd",
    name: "BNP Paribas GOmobile",
    description:
      "One of the best rated banking apps in Polish Appstore. Your personal portal to the world of finance: manage you accounts and other products; apply for credit from the application. Become our client and receive a physical payment card without visiting our branch or waiting for delivery. It's all here!",
    tech: "Swift, RxSwift, MVVM, Apple Wallet, using Xcodegen",
    url: "https://www.bnpparibas.pl/aplikacja-mobilna-go-mobile",
  },
  {
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/nierebinski-c407c.appspot.com/o/Zrzut%20ekranu%202019-10-9%20o%2000.10.43.png?alt=media&token=bc4ac4d6-0840-4e30-839b-f50978744b3b",
    name: "Le Temps",
    description:
      "Application for a Swiss economic newswpaper. Fueled by data from GraphQL API, 100% written in Swift. Some content is hidden behind a paywall and is accessible via in-app purchases.",
    tech: "Swift, GraphQL, Realm, VIPER architecture (iOS), Apple Payments",
    url: "https://apps.apple.com/pl/app/le-temps/id455659364?l=pl",
  },
  {
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/nierebinski-c407c.appspot.com/o/Zrzut%20ekranu%202019-10-9%20o%2000.06.19.png?alt=media&token=db24e59d-1688-40c3-be91-608de5040038",
    name: "Blic.rs",
    description:
      "Mobile application for the biggest Serbian daily newspaper - Blic. Serves content to read, watch and follow live. Uses lots of rich media.",
    tech: "Swift with small addition of Objective-C modules, Realm, VIPER architecture (iOS)",
    url: "https://apps.apple.com/pl/app/blic/id518272723?l=pl",
  },
  {
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fnoizz.png?alt=media&token=62c7ff4d-3be7-43f3-82e8-cd3f06844828",
    name: "Noizz",
    description:
      "Application for the biggest Polish website for millennials. Uses lots of rich media.",
    tech: "Swift with small addition of Objective-C modules, Realm, VIPER architecture (iOS)",
    url: "https://itunes.apple.com/pl/app/noizz/id1334217499?l=pl&mt=8",
  },
  {
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fonet.jpg?alt=media&token=7f9cb5a0-bec4-4277-a0fc-874433c9e90a",
    name: "Onet",
    url: "http://aplikacje.onet.pl/onet",
    tech: "Swift with small addition of Objective-C modules (iOS)",
    description:
      'An INMA 2017 award-winning content mobile application serving content from <a href="https://www.onet.pl/">Onet.pl</a> - the biggest Polish news & content portal - and associated websites. It makes use of text-to-speech technology for reading articles, a feature you can run using a voice command. The application was an enormous undertaking with many developer teams involved. We\'re really happy that it was met with appreciation.',
  },
  {
    name: "Fakt 24",
    tech: "Swift with small addition of Objective-C modules, Realm, VIPER architecture (iOS)",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Ffakt24.jpg?alt=media&token=1735b2b2-abc9-411a-abe9-6f61014f4a76",
    url: "https://itunes.apple.com/app/apple-store/id1202840740?mt=8",
    description:
      "Mobile application for the biggest Polish daily newspaper - Fakt. Serves content to read, watch and follow live. Uses lots of rich media.",
  },
  {
    url: "http://www.fakt.pl/",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Ffakt.jpg?alt=media&token=d44a8c83-c8df-4b20-aed8-7d4d3518986b",
    name: "m.fakt.pl",
    tech: "Node.js - multiple internal web services and a template engine",
    description:
      "Mobile site for the biggest Polish daily newspaper - Fakt. The site served as a very popular lighter version of the desktop website, with the same editorial content and a design better suited for mobile devices. Now the website is unified in a responsive way, but its principle of operation stays the same.",
  },
  {
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fwpunkt.jpg?alt=media&token=1893da93-7554-477b-992e-83d36db30a83",
    name: "Newsweek wPunkt",
    url: "http://www.newsweek.pl/wpunkt",
    tech: "Node.js + JSON-RPC 2.0 (API)",
    description:
      'The application\'s purpose is to deliver every morning a batch of content: prepared by <a href="http://www.newsweek.pl/">Newsweek Polska</a> editorial team, curated or syndicated. After reading all of the articles, the user gets awarded with a bonus content presenting important or interesting events that had happened on that day. Our team designed and delivered API for the application.',
  },
  {
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fnewsweek.jpg?alt=media&token=215bc567-e209-42f8-9c12-102b5c06ef68",
    title: "Newsweek Polska",
    tech: "ASP.NET + MS SQL, then JAVA + Spring + Hibernate",
    url: "http://www.newsweek.pl/",
    name: "Newsweek Polska",
    description:
      "Website for Polish edition of a popular American magazine. Its purpose was to serve quality content while handling heavy traffic, due to high popularity of the magazine.",
  },
  {
    name: "Forbes.pl",
    tech: "ASP.NET + MS SQL, then JAVA + Spring + Hibernate",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fforbes.jpg?alt=media&token=6d388dd4-33df-4f39-a9ae-42a7d933efa4",
    url: "https://www.forbes.pl/",
    description:
      "Website for Polish edition of a popular American business magazine. Apart from serving content prepared by the editorial team, the website's biggest feature was presenting various rankings: top 100 richest Poles or celebrities, highest valued brands etc. It's also home to various Polish business events and awards. Again with rankings and valuable insights.",
  },
  {
    name: "Experts' Club",
    url: "http://www.ofeminin.pl/klub-ekspertek/home.html",
    description:
      "The Experts' club (in Polish: Klub Expertek) was an original platform allowing the users to subscribe to test and evaluate various products such as perfumes, cosmetics, sportswear, but also mobile phones. Chosen users would receive a package containing products to test and was allowed to keep the products under condition she submitted her rating and evaluation survey for the products. <br /> The platform was internationalized by us for other editions of aufeminin websites.",
    tech: "classic ASP => ASP.NET, MS SQL",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fexpert-club.jpg?alt=media&token=a482f291-445c-4f39-b54c-521218a7328c",
  },
  {
    name: "ofeminin.pl",
    url: "http://www.ofeminin.pl/",
    tech: "classic ASP, MS SQL",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fofeminin.jpg?alt=media&token=fe38f54d-5962-4f63-aca2-f00d5ff1bf5b",
    description:
      'Polish edition of popular French website for women, <a href="http://www.aufeminin.com/" target="_blank" rel="noreferrer">aufeminin.com</a>. I was leading the team implementing and mantaining the website, creating contests and quizz engines for users and keeping up-to-date with my foreign counterparts running editions for other countries/languages.',
  },
  {
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fradio-euro.jpeg?alt=media&token=312cbee3-d294-48e7-9e6f-8a4903609b91",
    name: "Polskie Radio Euro",
    tech: "ASP.NET, MS SQL",
    url: "http://www.polskieradio.pl/10,Czworka",
    description:
      "Polskie Radio Euro was a Polish National Radio programme for young people covering music, sports and technology. The website offered live audio and video streaming of all audition, music and art events, live concerts and such. Later on it was incorporated in Polish National Radio portal and the Radio Euro was rebranded to <em>Czwórka</e> (number four).",
  },
];
export const PodcastData = [
  {
    title: "Mage: the Podcast",
    url: "https://magethepodcast.com/category/podcast/",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2F72d24f70-2491-0136-c266-7d73a919276a.jpg?alt=media&token=0105013d-2138-4ec8-87d9-dea4a04856f4",
    description:
      "If you're not a paper/tabletop RPG player, you probably won't be insterested in this podcast. On the other hand. if you <em>are</em>, then you already know that <strong>Mage</strong> is one of the most demanding Role Playing Games today. And this podcast is a great help for players and game masters alike.",
  },
  {
    title: "Swift by Sundell",
    url: "https://www.swiftbysundell.com/podcast",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fec708e40-5a50-0135-902c-63f4b61a9224.jpg?alt=media&token=e605b772-222b-40d1-9f7e-4008c9217551",
    description:
      'It\'s a very popular podcast and if you\'re into programming in Swift you have to look at <a href="https://www.swiftbysundell.com" title="John Sundell\'s blog" target="_blank" rel="noreferrer">John Sundell\'s blog</a> or listen to his podcast. <a href="https://twitter.com/johnsundell" target="_blank" rel="noreferrer">John</a> is an experienced iOS developer, open-source libs creator, and at the same time humble enough to invite experts on certain fields as guests to his podcast.',
  },
  {
    title: "Stacktrace",
    url: "https://9to5mac.com/guides/stacktrace/",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2F40086460-0aa1-0136-c264-7d73a919276a.jpg?alt=media&token=e780d783-dc88-4731-ab69-56ef0cfafa23",
    description:
      'Another <a href="https://9to5mac.com" title="9to5Mac website" target="_blank">9to5Mac</a>\'s Apple-centric podcast, hosted by <a href="https://www.swiftbysundell.com" title="John Sundell\'s blog" target="_blank">John Sundell</a> and <a href="https://twitter.com/_inside" title="Gui Rambo on Twitter" target="_blank" rel="noreferrer">Gui Rambo</a>. Full of bright and funny comments made from Apple user and developer perspective. Freshes news, best predictions and hands-on reviews. Highly recommended not only to programmers.',
  },
  {
    title: "In our time",
    url: "http://www.bbc.co.uk/programmes/b006qykl",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2F5e6125f0-0424-012e-f9a0-00163e1b201c.jpg?alt=media&token=1e4a84b0-a88b-44f8-abfd-b8840e1d526b",
    description:
      "I love history talks. Sure, we all had history lessons at school so we got the bacics covered pretty well. But this podcast is based on BBC 4's auditions covering in-depth lesser known events or idead. Did you know that there was a Populist Party in the USA in XIX century?",
  },
  {
    url: "https://irlpodcast.org/",
    title: "IRL Podcast",
    description:
      "IRL: Online Life Is Real Life.<br /> We all use internet for entertainment, communication with friends, shopping or learning. But there are still some mysteries or things that are a bit hard to understand for an average user. Don't be mistaken: if you're a hardcore geek, you can still learn a lot from this podcast. Plus: it's a bit humorous.",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fb75e8f10-31e2-0135-52f9-452518e2d253.jpg?alt=media&token=4043ccec-0fe1-4470-9816-dee29f9516c0",
  },
  {
    title: "Zombie vs Zwierz",
    url: "https://www.facebook.com/zvzpodcast/",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fzvz.png?alt=media&token=642012b2-faea-45d2-a935-5dbecadc2c63",
    language: "🇵🇱",
    description:
      "One of the best. A popcultural - or maybe even cultural - podcast anchored by two popular Polish bloggers and influencers. They can always draw serious conclusions from apparently light topics or jokes. Can't wait for the next season to start!",
  },
  {
    url: "http://moonshot.audio/",
    title: "Moonshot",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Ff14b1c90-4049-0134-eba6-0d50f522381b.jpg?alt=media&token=b744d539-2dc5-491e-afb2-6c4168799c51",
    description:
      "Do you remember all the crazy ideas you dreamed to realize? All the <em>what ifs</em>?. Those guys actually muse aloud about realizing them. And they do that pretty well, covering tech, futurism, space travel or plain old startup business.",
  },
  {
    url: "http://www.radiofreeskaro.com/",
    title: "Radio Free Skaro",
    description:
      "Yes, I'm a whovian. I love Doctor Who and if you'd ask me why, I wouldn't probably know what to say. It's a great show. The greatest. &laquo;Radio Free Skaro&raquo; is probably the most popular podcast covering all the things in the Doctor Who world: episodes, story arcs, showrunner and actor replacements and fandom-discovered conspiracy theories.",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2F8e5389c0-2883-012e-072b-00163e1b201c.jpg?alt=media&token=4cfab1d7-56ac-4942-9b57-62a015c6486c",
  },
  {
    url: "http://revisionisthistory.com/",
    title: "Revisionist History",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2Fac371bd0-094f-0134-9ce1-59d98c6b72b8.jpg?alt=media&token=265a83bf-7de1-41de-9f5e-a6bdc1b12104",
    description:
      'History is written by winners. But you can always reinterper it. That\'s what Malcolm Gladwell is doing in this podcast. A fresh, sceptic and inquiring look at ideas, people or single events. And the author is a fascinating person: check out this <a href="http://businessinsider.com.pl/international/malcolm-gladwell-tells-us-about-his-beef-with-billionaires-police-violence-and-how/hzzln1g" target="_blank" rel="noreferrer">interview with Malcolm Gladwell</a>. You don\'t have to agree with him, to know that hearing out his ideas is worthwhile.',
  },
  {
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2F0cc43410-1d2f-012e-0175-00163e1b201c.jpg?alt=media&token=d8913efa-2b0a-4c34-bbf8-1299c1dc6421",
    title: "99% Invisible",
    url: "http://99percentinvisible.org/",
    description:
      "&laquo;Ever wonder how inflatable men came to be regular fixtures at used car lots? Curious about the origin of the fortune cookie? Want to know why Sigmund Freud opted for a couch over an armchair?&raquo<br /> For me it all started with an episode about the <a target=\"blank\" href=\"http://99percentinvisible.org/episode/the-pool-and-the-stream/\">history of skate parks</a>. No, it didn't sound anything like fascinating to me, but I gave it a try and I was amazed. It's the little thing I don't notice, what's amazing.",
  },
  {
    title: "Mana Mana",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/can-into-space.appspot.com/o/homesite%2F6c83ee10-d3f6-0134-ebcf-4114446340cb.jpg?alt=media&token=ef3c7591-ba7e-4762-9f8e-8190bdfc2fa8",
    language: "🇵🇱",
    url: "http://jestemgeekiem.pl/podcast/",
    description:
      "Two ladies working in gamedev discuss games, game industry and geek popculture with loads of good humour. I was happy to get some movie recommendations from them, great movies I could've missed beacause they weren't blockbusters or weren't shown at my cinema.",
  },
];
