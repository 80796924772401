import React, { Component } from "react";
import ResponsiveImage from "./ResponsiveImage";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whitened: props.isWhitened,
    };
  }

  handleToggling = (newState) => {
    return this.toggleIfNeeded(newState);
  };

  toggleIfNeeded = (newState) => {
    if (this.state.whitened !== newState) {
      this.setState({ whitened: newState });
    }
  };

  render() {
    return (
      <div
        id="navbar"
        role="navigation"
        className={this.state.whitened ? "navBar navBarWhite" : "navBar"}
      >
        <div className="contentWrapper navBarWrapper">
          <a href="/" className="logoLink">
            <ResponsiveImage
              customClassName="spaceImage"
              alt="can_into_space"
              publicURL={process.env.PUBLIC_URL}
              fileName="images/space.png"
            />
          </a>
          <a className="contactLink" href="mailto:contact@caninto.space">
            contact@caninto.space
          </a>
        </div>
      </div>
    );
  }
}

export default NavBar;
