import React, { Component } from "react";

const splitFileName = (fileName) => {
  let dotIndex = fileName.lastIndexOf(".");

  return {
    name: fileName.substr(0, dotIndex),
    extension: fileName.substr(dotIndex + 1),
  };
};

const createSourceSetString = (publicURL, filenameSet) => {
  return (
    publicURL +
    "/" +
    filenameSet.name +
    "." +
    filenameSet.extension +
    " 1x, " +
    publicURL +
    "/" +
    filenameSet.name +
    "@2x." +
    filenameSet.extension +
    " 2x, " +
    publicURL +
    "/" +
    filenameSet.name +
    "@3x." +
    filenameSet.extension +
    " 3x"
  );
};

let embedInLink = (url, title, openInNewWindow, element) => {
  return (
    <a
      href={url}
      title={title}
      target={openInNewWindow ? "_blank" : ""}
      rel={"noreferrer"}
    >
      {element}
    </a>
  );
};
class ResponsiveImage extends Component {
  render() {
    let publicURL = this.props.publicURL;
    let fileName = this.props.fileName;
    let description = this.props.description || fileName;
    let className = this.props.customClassName || "";
    let linkURL = this.props.linkURL;
    let linkTitle = this.props.linkTitle;
    let openInNewWindow = this.props.openInNewWindow;

    if (typeof fileName === "undefined") {
      return null;
    }

    let returnedImage = () => {
      return (
        <img
          src={publicURL + "/" + fileName}
          srcSet={createSourceSetString(publicURL, splitFileName(fileName))}
          alt={description}
          className={className}
        />
      );
    };

    if (linkURL && typeof linkURL === "string") {
      return embedInLink(linkURL, linkTitle, openInNewWindow, returnedImage());
    }

    return returnedImage();
  }
}

export default ResponsiveImage;
