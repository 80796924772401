import React, { Component } from "react";
import HomeTile from "./HomeTile";

import { HomeTileData } from "../const/data";

class HomePageContainer extends Component {
  items = (size) => {
    const items = [];
    for (var i = 1; i <= size; i++) {
      items.push(i);
    }
    return items;
  };

  render = () => {
    return (
      <div className="tiles">
        {HomeTileData.map((item, index) => (
          <HomeTile
            key={index}
            index={index}
            header={item.header}
            subheader={item.subheader}
            specialClassName={item.specialClassName}
            content={item.content ? item.content : []}
            alternativeImageText={item.image ? item.image.alt : undefined}
            imageFile={item.image ? item.image.file : undefined}
            linkURL={item.image ? item.image.linkURL : undefined}
            linkTitle={item.image ? item.image.linkTitle : undefined}
            openInNewWindow={item.image ? item.image.openInNewWindow : false}
            customClassName={item.image.className}
          />
        ))}
      </div>
    );
  };
}

export default HomePageContainer;
