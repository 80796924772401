import React, { Component } from "react";

class Page404 extends Component {
  render = () => {
    return (
      <div className="notFoundContainer">
        <h1>I guess you're a bit lost, ain't you?</h1>
        <h4>Or are you looking for some poetry?</h4>
        <p>
          I don't know why, but I suppose you could. Here you are, then. <br />
          If not, you can just{" "}
          <a href="/" title="Go back to home page">
            go back to the home page
          </a>
          .
        </p>
        <h4>***</h4>
        <h4>
          T.S. Eliot - <em>SWEENEY AMONG THE NIGHTINGALES</em>
        </h4>
        <p>
          Apeneck Sweeney spreads his knees
          <br />
          Letting his arms hang down to laugh,
          <br />
          The zebra stripes along his jaw
          <br />
          Swelling to maculate giraffe.
        </p>

        <p>
          The circles of the stormy moon
          <br />
          Slide westward toward the River Plate,
          <br />
          Death and the Raven drift above
          <br />
          And Sweeney guards the horned gate.
        </p>

        <p>
          Gloomy Orion and the Dog
          <br />
          Are veiled; and hushed the shrunken seas;
          <br />
          The person in the Spanish cape
          <br />
          Tries to sit on Sweeney's knees
        </p>

        <p>
          Slips and pulls the table cloth
          <br />
          Overturns a coffee-cup,
          <br />
          Reorganized upon the floor
          <br />
          She yawns and draws a stocking up;
        </p>

        <p>
          The silent man in mocha brown
          <br />
          Sprawls at the window-sill and gapes;
          <br />
          The waiter brings in oranges
          <br />
          Bananas figs and hothouse grapes;
        </p>

        <p>
          The silent vertebrate in brown
          <br />
          Contracts and concentrates, withdraws;
          <br />
          Rachel née Rabinovitch
          <br />
          Tears at the grapes with murderous paws;
        </p>

        <p>
          She and the lady in the cape
          <br />
          Are suspect, thought to be in league;
          <br />
          Therefore the man with heavy eyes
          <br />
          Declines the gambit, shows fatigue,
        </p>

        <p>
          Leaves the room and reappears
          <br />
          Outside the window, leaning in,
          <br />
          Branches of wistaria
          <br />
          Circumscribe a golden grin;
        </p>

        <p>
          The host with someone indistinct
          <br />
          Converses at the door apart,
          <br />
          The nightingales are singing near
          <br />
          The Convent of the Sacred Heart,
        </p>

        <p>
          And sang within the bloody wood
          <br />
          When Agamemnon cried aloud,
          <br />
          And let their liquid droppings fall
          <br />
          To stain the stiff dishonoured shroud
        </p>

        <h4>Do you want some more?</h4>
        <h4>***</h4>
        <h4>
          Lewis Carroll - <em>Jabberwocky</em>
        </h4>
        <p>
          Twas brillig, and the slithy toves
          <br />
          Did gyre and gimble in the wabe;
          <br />
          All mimsy were the borogoves,
          <br />
          And the mome raths outgrabe.
        </p>

        <p>
          “Beware the Jabberwock, my son!
          <br />
          The jaws that bite, the claws that catch!
          <br />
          Beware the Jubjub bird, and shun
          <br />
          The frumious Bandersnatch!”
        </p>

        <p>
          He took his vorpal sword in hand:
          <br />
          Long time the manxome foe he sought—
          <br />
          So rested he by the Tumtum tree,
          <br />
          And stood awhile in thought.
        </p>

        <p>
          And as in uffish thought he stood,
          <br />
          The Jabberwock, with eyes of flame,
          <br />
          Came whiffling through the tulgey wood,
          <br />
          And burbled as it came!
        </p>

        <p>
          One, two! One, two! And through and through
          <br />
          The vorpal blade went snicker-snack!
          <br />
          He left it dead, and with its head
          <br />
          He went galumphing back.
        </p>

        <p>
          “And hast thou slain the Jabberwock?
          <br />
          Come to my arms, my beamish boy!
          <br />
          O frabjous day! Callooh! Callay!”
          <br />
          He chortled in his joy.
        </p>

        <p>
          ’Twas brillig, and the slithy toves
          <br />
          Did gyre and gimble in the wabe;
          <br />
          All mimsy were the borogoves,
          <br />
          And the mome raths outgrabe.
        </p>
        <h4>I hope you liked it</h4>
        <p>
          Now you finally can{" "}
          <a href="/" title="Go back to home page">
            go back to the home page
          </a>
          .
        </p>
      </div>
    );
  };
}

export default Page404;
