import React, { Component } from "react";
import reactLogo from "../assets/images/react-logo.svg";
import firebaseLogo from "../assets/images/firebase-logo.svg";
import vscodeLogo from "../assets/images/vscode-logo.svg";
import webpackLogo from "../assets/images/webpack-logo.svg";
import yarnLogo from "../assets/images/yarn-logo.svg";

class Footer extends Component {
  render = () => {
    return (
      <div className="appFooter">
        <div className="contentWrapper">
          <div className="footerInside">
            <p>
              <em>
                <strong>Can into space</strong> <em>v1.5.1</em> was made with:
              </em>
            </p>
            <div className="footerTools">
              <a
                className="footerLink"
                href="https://facebook.github.io/react/"
                title="React JS"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={reactLogo} alt="React JS" />
              </a>
              <a
                className="footerLink"
                href="https://firebase.google.com/"
                title="Google Firebase"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={firebaseLogo} alt="Google Firebase" />
              </a>
              <a
                className="footerLink"
                href="https://code.visualstudio.com/"
                title="Visual Studio Code"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={vscodeLogo} alt="VS Code" />
              </a>
              <a
                className="footerLink"
                href="https://webpack.github.io/"
                title="Webpack Module Bundler"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={webpackLogo} alt="Webpack" />
              </a>
              <a
                className="footerLink tripleLink"
                href="https://yarnpkg.com/lang/en/"
                title="Yarn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={yarnLogo} alt="Yarn" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default Footer;
