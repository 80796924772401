import React, { Component } from "react";
import Project from "./Project";
import { ProjectData } from "../const/data";

class ProjectSite extends Component {
  render = () => {
    return (
      <div>
        <div className="projectIntro">
          <h2 className="or">My projects portfolio</h2>
          <p>
            Here are selected projects I'd like to share with you. Most of them
            were a result of team work. Many times teams from multiple locations
            or even timezones were involved. Of course, I don't maintain the
            websites now. New teams have been taking care of them. Sometimes so
            many years have passed, that a redesign or a relaunch was requested
            by the product managers. But I like to think that I've left a solid
            footprint on every product I developed.
          </p>
        </div>
        <div className="projectContainer">
          {ProjectData.map((item, index) => (
            <Project
              key={index}
              name={item.name}
              url={item.url}
              imageUrl={item.imageUrl}
              description={item.description}
              tech={item.tech}
            />
          ))}
        </div>
      </div>
    );
  };
}

export default ProjectSite;
