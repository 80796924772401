import React, { Component } from "react";
import Podcast from "./Podcast";
import { PodcastData } from "../const/data";

class PodcastContainer extends Component {
  render = () => {
    return (
      <div>
        <div className="podIntro">
          <h2 className="or">Podcasts I listen to</h2>
          <p>
            Some time ago I started listening to a lot of podcasts. Some concern
            technology and programming, some concern history or civilizational
            issues and, of course, some are really geeky. I chose to present you
            some I just have to listen once a new episode is out. Enjoy!
          </p>
        </div>
        <div className="podContainer">
          {PodcastData.map((item, index) => (
            <Podcast
              title={item.title}
              description={item.description}
              key={index}
              language={item.language}
              url={item.url}
              imageUrl={item.imageUrl}
            />
          ))}
        </div>
      </div>
    );
  };
}

export default PodcastContainer;
