import React, { Component } from "react";
import ResponsiveImage from "./ResponsiveImage";

const setInnerHTML = (content) => {
  return { __html: content };
};

class HomeTile extends Component {
  render = () => {
    return (
      <div
        className={
          this.props.specialClassName
            ? "tile " + this.props.specialClassName
            : "tile"
        }
        key={this.props.index}
      >
        <div className="tileHeader">
          <h3>
            <span>{this.props.header}</span>
          </h3>
        </div>
        <div className="tileInside">
          <h4>{this.props.subheader}</h4>
          <div className="tileBody">
            {this.props.content.map((contentItem, index) => (
              <p
                key={this.props.index + "." + index}
                dangerouslySetInnerHTML={setInnerHTML(contentItem)}
              />
            ))}
          </div>
        </div>
        <div className="tileBottom">
          <ResponsiveImage
            customClassName={this.props.customClassName || "tileImage"}
            alt={this.props.imageAlternativeText}
            publicURL={process.env.PUBLIC_URL}
            fileName={this.props.imageFile}
            linkURL={this.props.linkURL}
            linkTitle={this.props.linkTitle}
            openInNewWindow={this.props.openInNewWindow}
          />
        </div>
      </div>
    );
  };
}

export default HomeTile;
