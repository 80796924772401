import React, { Component } from "react";

const setInnerHTML = (content) => {
  return { __html: content };
};

class Project extends Component {
  projectImageStyle = {
    background: "url(" + this.props.imageUrl + ") top center no-repeat",
    height: "320px",
    backgroundSize: "cover",
  };

  render = () => {
    return (
      <div className="project">
        <h3>
          <a
            href={this.props.url}
            title={this.props.name}
            target="_blank"
            rel="noreferrer"
          >
            {this.props.name}
          </a>
        </h3>
        <div className="projectImage" style={this.projectImageStyle}>
          <div className="over" />
        </div>
        <p
          className="projCast"
          dangerouslySetInnerHTML={setInnerHTML(this.props.description)}
        />
        <p className="projTech">
          Main technologies: <em>{this.props.tech}</em>
        </p>
        <p className="projLink">
          <strong>
            Go to{" "}
            <a
              href={this.props.url}
              title={this.props.name + "'s site"}
              target="_blank"
              rel="noreferrer"
            >
              the project's website
            </a>
          </strong>
        </p>
      </div>
    );
  };
}

export default Project;
